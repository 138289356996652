import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import { ABSENT_TYPES } from '@/const/status'

export default {
  namespaced: true,
  state: {
    studentExaminationRooms: [],
    codeByExaminationRooms: [],
    scoreByCreditClass: [],
    allTrainingSystem: [],
    coursesSemesters: [],
    examinationRoomSubjects: [],
    subjects: [],
    title: [],
    checkExaminationDataSources: [],
    checkStudentRemakeExaminationRooms: [],
    absentTypes: ABSENT_TYPES,
    dataStudentsExaminedExport: [],
    dataExamScoreSheetTheoryClassExport: [],
    dataExamScoreSheetPracticeClassExport: [],
  },
  getters: {
    studentExaminationRooms: state => state.studentExaminationRooms,
    allTrainingSystem: state => state.allTrainingSystem,
    coursesSemesters: state => state.coursesSemesters,
    examinationRoomSubjects: state => state.examinationRoomSubjects,
    subjects: state => state.subjects,
    codeByExaminationRooms: state => state.codeByExaminationRooms,
    scoreByCreditClass: state => state.scoreByCreditClass,
    title: state => state.title,
    checkExaminationDataSources: state => state.checkExaminationDataSources,
    checkStudentRemakeExaminationRooms: state => state.checkStudentRemakeExaminationRooms,
    absentTypes: state => state.absentTypes,
    dataStudentsExaminedExport: state => state.dataStudentsExaminedExport,
    dataExamScoreSheetTheoryClassExport: state => state.dataExamScoreSheetTheoryClassExport,
    dataExamScoreSheetPracticeClassExport: state => state.dataExamScoreSheetPracticeClassExport,
  },
  mutations: {
    SET_STUDENTEXAMINATIONROOMS: (state, { data }) => {
      state.studentExaminationRooms = data
    },
    SET_CODE_BY_EXAMINATION_ROOMS: (state, { data }) => {
      state.codeByExaminationRooms = data
    },

    SET_SUBJECT: (state, { data }) => {
      state.subjects = data
    },

    SET_TITLE: (state, { data }) => {
      state.title = data
    },

    SET_EXAMINATIONROOMSUBJECTS: (state, { data }) => {
      state.examinationRoomSubjects = data
    },

    SET_COURSESSEMESTERS: (state, { data }) => {
      state.coursesSemesters = data
    },

    SET_ALL: (state, { allTrainingSystem }) => {
      state.allTrainingSystem = allTrainingSystem
    },
    SET_SCORE_BY_CREDIT_CLASS: (state, { records }) => {
      state.scoreByCreditClass = records
    },
    SET_CHECK_EXAMINATION_DATA_SOURCES: (state, data) => {
      state.checkExaminationDataSources = data
    },
    SET_CHECK_STUDENT_REMAKE_EXAMINATION_ROOM: (state, data) => {
      state.checkStudentRemakeExaminationRooms = data
    },
    SET_DATA_STUDENTS_EXAMINED_EXPORT: (state, { dataExport }) => {
      state.dataStudentsExaminedExport = dataExport
    },
    SET_DATA_EXAM_SCORE_SHEET_THEORY_CLASS_EXPORT: (state, { dataExport }) => {
      state.dataExamScoreSheetTheoryClassExport = dataExport
    },
    SET_DATA_EXAM_SCORE_SHEET_PRACTICE_CLASS_EXPORT: (state, { dataExport }) => {
      state.dataExamScoreSheetPracticeClassExport = dataExport
    },
  },
  actions: {
    async begin({ commit }) {
      try {
        const response = await axios.get('uni/revenue_plan/training_systems')

        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_ALL', { allTrainingSystem: response.data.data.rs })
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getSubjects({ commit }, params) {
      try {
        const response = await axios.get('uni/examinationRooms/courseSemesterSubject', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_SUBJECT', { data: response.data.data })
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getTitle({ commit }, params) {
      try {
        const response = await axios.get('uni/studentExaminationRooms/studentTitleExaminationRooms', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_TITLE', { data: response.data.data })
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    // eslint-disable-next-line no-unused-vars
    async exportPdfDanhSachSinhVien({ commit }, params) {
      try {
        const response = await axios.get('uni/reports/exportPdfDanhSachSinhVien', { params, responseType: 'blob' })
        if (response.status === 200) {
          return response.data
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async getExaminationRoomSubjects({ commit }, params) {
      try {
        const response = await axios.get('uni/studentExaminationRooms/examinationRoomSubjects', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_EXAMINATIONROOMSUBJECTS', { data: response.data.data })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getStudentExaminationRooms({ commit }, params) {
      try {
        const response = await axios.get('uni/studentExaminationRooms', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_STUDENTEXAMINATIONROOMS', { data: response.data.data })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getCodeByExaminationRooms({ commit }, params) {
      try {
        const response = await axios.get('uni/studentExaminationRooms/codes', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_CODE_BY_EXAMINATION_ROOMS', { data: response.data.data })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getCoursesSemesters({ commit }, params) {
      try {
        const response = await axios.get('uni/examinationRooms/course_semesters', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_COURSESSEMESTERS', { data: response.data.data })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async updateStudentExaminationRoom({ commit }, params) {
      try {
        const response = await axios.put('uni/studentExaminationRooms', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async banStudentExaminationRooms({ commit }, params) {
      try {
        const response = await axios.put('uni/studentExaminationRooms/banStudent', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deductedStudentExaminationRooms({ commit }, params) {
      try {
        const response = await axios.put(`uni/studentExaminationRooms/deductedStudent/${params.deductId}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async changeExaminationRooms({ commit }, params) {
      try {
        const response = await axios.put('uni/studentExaminationRooms/changeExaminationRooms', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async saveScoresByCode({ commit }, params) {
      try {
        const response = await axios.put(`uni/studentExaminationRooms/saveScoresByCode/${params.scoresByCode[0].examinationRoomId}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getScoreByCreditClass({ commit }, params) {
      try {
        const response = await axios.get('uni/studentExaminationRooms/creditClass', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const {
            total,
            records,
          } = data.data
          commit('SET_SCORE_BY_CREDIT_CLASS', {
            total,
            records,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getCheckExaminationPapers({ commit }, params) {
      try {
        const response = await axios.get('uni/studentExaminationRooms/checkExaminationPapers', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_CHECK_EXAMINATION_DATA_SOURCES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getCheckStudentRemakeExaminationRoom({ commit }, params) {
      try {
        const response = await axios.get('uni/studentExaminationRooms/getCheckStudentRemakeExaminationRoom', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_CHECK_STUDENT_REMAKE_EXAMINATION_ROOM', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async createCheckExaminationPaper({ commit }, params) {
      try {
        const response = await axios.post('uni/studentExaminationRooms/checkExaminationPapers', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async saveAbsent({ commit }, params) {
      try {
        const response = await axios.put(`uni/studentExaminationRooms/saveAbsent/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async exportPdfStudentsExaminationScore({ commit }, params) {
      try {
        const response = await axios.get('uni/reports/exportPdfStudentsExaminationScore', { params, responseType: 'blob' })
        if (response.status === 200) {
          return response.data
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async exportPdfScoreHeaderCode({ commit }, params) {
      try {
        const response = await axios.get('uni/reports/exportPdfScoreHeaderCode', { params, responseType: 'blob' })
        if (response.status === 200) {
          return response.data
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async downloadStudentsExaminedExport({ commit }, params) {
      try {
        const responseStudentsExamined = await axios({
          method: 'get',
          url: 'uni/studentExaminationRooms/export-students-examined',
          responseType: 'blob',
          params,
        })
        commit('SET_DATA_STUDENTS_EXAMINED_EXPORT', { dataExport: responseStudentsExamined })
      } catch (e) {
        handleCatch(e)
      }
    },
    async downloadExamScoreSheetExportTheoryClass({ commit }, params) {
      try {
        const responseExamScoreSheet = await axios({
          method: 'get',
          url: 'uni/studentExaminationRooms/export-exam-score-sheet-theory-class',
          responseType: 'blob',
          params,
        })
        commit('SET_DATA_EXAM_SCORE_SHEET_THEORY_CLASS_EXPORT', { dataExport: responseExamScoreSheet })
      } catch (e) {
        handleCatch(e)
      }
    },
    async downloadExamScoreSheetExportPracticeClass({ commit }, params) {
      try {
        const responseExamScoreSheet = await axios({
          method: 'get',
          url: 'uni/studentExaminationRooms/export-exam-score-sheet-practice-class',
          responseType: 'blob',
          params,
        })
        commit('SET_DATA_EXAM_SCORE_SHEET_PRACTICE_CLASS_EXPORT', { dataExport: responseExamScoreSheet })
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
